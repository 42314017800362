///<reference path="../../../node_modules/@types/jquery/index.d.ts"/>

export class Common {
    static showLoader(): any {
        $("#loader").show("fast");
    }

    static hideLoader(): any {
        $("#loader").hide("fast");
    }

    static getLocalStorageValue(key: string) {
        try {
            const lsval = localStorage.getItem(key);
            if (!lsval) return null;
            return JSON.parse(lsval);
        }
        catch (ex) {
            return null;
        }
    }

    static saveLocalStorageValue(key: string, value: any) {
        try {
            if (value === null) {
                localStorage.removeItem(key);
            } else {
                const json = JSON.stringify(value);
                localStorage.setItem(key, json);
            }
        }
        catch (ex) {
            return;
        }
    }
    static copyTextToClipboard(text: string): boolean {
        const textArea = document.createElement("textarea");

        //
        // *** This styling is an extra step which is likely not required. ***
        //
        // Why is it here? To ensure:
        // 1. the element is able to have focus and selection.
        // 2. if element was to flash render it has minimal visual impact.
        // 3. less flakyness with selection and copying which **might** occur if
        //    the textarea element is not visible.
        //
        // The likelihood is the element won't even render, not even a flash,
        // so some of these are just precautions. However in IE the element
        // is visible whilst the popup box asking the user for permission for
        // the web page to copy to the clipboard.
        //

        // Place in top-left corner of screen regardless of scroll position.
        textArea.style.position = "fixed";
        textArea.style.top = "0";
        textArea.style.left = "0";

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = "2em";
        textArea.style.height = "2em";

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = "0";

        // Clean up any borders.
        textArea.style.border = "none";
        textArea.style.outline = "none";
        textArea.style.boxShadow = "none";

        // Avoid flash of white box if rendered for any reason.
        textArea.style.background = "transparent";


        textArea.value = text;

        document.body.appendChild(textArea);

        textArea.select();

        let successful: boolean;
        try {
            successful = document.execCommand("copy");
        } catch (err) {
            successful = false;
        }

        document.body.removeChild(textArea);
        return successful;
    }


}
//export to global window space
const w: any = window;
w.Common = Common;
