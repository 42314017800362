///<reference path="../../../node_modules/@types/jquery/index.d.ts"/>
import {Common} from "./common";

class Management {
    checkBackupInterval: number = null;
    checkJobStatusTimeout: number = null;
    startJobStatusUpdatePolls: number;

    constructor() {
        this.addEvents();
    }

    // Add events
    addEvents() {
        var management = this;

        // Add events on page loaded
        $(() => {
            // Show details when user cliked on a row
            $(document).on("click",
                ".item-details-link",
                (ev) => {
                    management.toggleItemDetails($(ev.currentTarget));
                    return false;
                });

            // Perform ajax request on click on ajax-link
            $(document).on("click",
                ".ajax-link",
                (ev) => {

                    var $clickedElement = $(ev.currentTarget);

                    management.performAjaxLinkRequest($clickedElement);
                    return false;
                });

            // Submit form when user clicks on submitbutton in for example popup
            $(document).on("click",
                ".submit-form",
                (ev) => {

                    var $clickedElement = $(ev.currentTarget);

                    $clickedElement.closest(".modal-content").find("form").submit();

                    return false;
                });

            // Submit event on ajax forms
            $(document).on("submit",
                ".ajax-form",
                (ev) => {

                    var $form = $(ev.currentTarget);
                    management.saveAjaxForm($form);
                    return false;
                });

            // Submit event on ajax forms
            $(document).on("keyup",
                ".search-instance",
                () => {
                    Common.saveLocalStorageValue("search-instance", $(".search-instance").val());

                    management.filterInstances();
                });

            // Hide popup
            $(document).on("click",
                ".hide-popup",
                () => {
                    management.hidePopup();
                    return false;
                });

            // Show more info
            $(document).on("click",
                ".show-more-info-link",
                (ev) => {
                    let $clickedElement = $(ev.currentTarget);
                    let $moreInfoBox = $clickedElement.closest("tr").find(".more-info");

                    $moreInfoBox.toggle();

                    return false;
                });
            management.addUploadFileEvents(".upload-publish-file", ".upload-publish-file-area", true);
            management.addUploadFileEvents(".upload-license-file", ".upload-license-file-area", false);
            management.loadPartialContents();
        });
    }

    addUploadFileEvents(uploadWrapper:string, dropableArea: string, updateInstances:boolean) {
        // On drag for uploading publish file
        $(document).on("drag dragstart dragend dragover dragenter dragleave drop",
            dropableArea,
            e => {
                e.preventDefault();
                e.stopPropagation();
            });

        $(document).on("dragover dragenter",
            dropableArea,
            e => {
                const $e = $(e.currentTarget);
                $e.addClass("is-dragover");
            });
        $(document).on("dragleave dragend drop",
            dropableArea,
            e => {
                const $e = $(e.currentTarget);
                $e.removeClass("is-dragover");
            });

        $(document).on("drop",
            dropableArea,
            e => {
                const $box = $(uploadWrapper);
                $box.show();
                const droppedFiles = (e.originalEvent as DragEvent).dataTransfer.files;
                const $form = $box.find("form");
                const formElement = $form.get(0) as HTMLFormElement;
                const ajaxData = new FormData(formElement);
                const $input = $box.find("input.file");
                const $errorDiv = $box.find(".error");
                const $uploadingDiv = $box.find(".uploading");
                const $successDiv = $box.find(".success");
                const $successMessagesUl = $successDiv.find("ul");
                $errorDiv.hide();
                $uploadingDiv.show();
                $successDiv.hide();
                $successMessagesUl.empty();
                const $errorMsg = $errorDiv.find("span");
 
                $.each(droppedFiles,
                    (i, file) => {
                        ajaxData.append("files", file);
                    });
                $.ajax({
                    url: $form.attr("action"),
                    type: $form.attr("method"),
                    data: ajaxData,
                    dataType: "json",
                    cache: false,
                    contentType: false,
                    processData: false,
                    complete() {
                        $uploadingDiv.hide();
                    },
                    success(data) {
                        $box.addClass(data.success ? "is-success" : "is-error");
                        if (!data.success) {
                            $errorMsg.text(data.error);
                            $errorDiv.show();
                        } else {
                            if (data.messages) {
                                for (let i = 0; i < data.messages.length; i++) {
                                    $successMessagesUl.append(`<li>${data.messages[i]}</li>`);
                                }
                            }
                            $successDiv.show();
                            if (updateInstances)
                                management.loadPartialContent("instances");
                        }
                    },
                    error(xhr, status, error) {
                        $errorMsg.text(status + ", " + error);
                        $errorDiv.show();
                    }
                });
            });
    }

    filterInstances() {

        if ($(".search-instance").length===0) return;
        const searchString = Common.getLocalStorageValue("search-instance");

        if (searchString != null)
            $(".search-instance").val(searchString);

        const searchValue = ($(".search-instance").val() as string).toLowerCase();

        $(".item-table tr").each((i,tr)=> {
            let match = false;
            $("td", $(tr)).each((i2, td) => {
                if ($(td).text().toLocaleLowerCase().indexOf(searchValue) !== -1) {
                    match = true;
                }
            });
            if (match) {
               $(tr).show();
            } else {
                $(tr).hide();
                
            }
        });
    }

    // Handle ajax link and response
    performAjaxLinkRequest($clickedElement: JQuery<Document>) {
        var management = this;

        // Perform ajax request
        $.ajax({
            url: $clickedElement.attr("href"),
            success(response) {

                // Contents to update on success
                const updateContents: string[] = $clickedElement.data("update-contents") != undefined ? $clickedElement.data("update-contents").split(",") : null;

                // If content ids to update, then update thoose contents
                if (updateContents != null) {
                    for (let i = 0; i < updateContents.length; i++) {
                        // Load each partial content
                        management.loadPartialContent(updateContents[i]);
                    }
                } else {
                    // else show popup
                    $(".popup").html(response);
                    $(".popup").show("slow");
                }
            }
        });

    }

    // Get ajax link response in popup
    saveAjaxForm($form: JQuery<Document>) {
        var management = this;

        $(".popup").show("slow");
        $("#error-panel").addClass("hidden");
        $(".has-error", $form).removeClass("has-error");

        // Perform ajax request (post or get depending on form method)
        $.ajax({
            url: $form.attr("action"),
            data: $form.serialize(),
            type: $form.attr("method"),
            success: (response)=> {

                // Everything went well
                if (response == null || response.length < 5||response.success) {

                    const triggerFunctions: string[] = $form.data("trigger-functions") != undefined
                        ? $form.data("trigger-functions").split(",")
                        : null;
                    if (triggerFunctions != null) {
                        for (let i = 0; i < triggerFunctions.length; i++) {
                            switch (triggerFunctions[i]) {
                                case "startJobStatusUpdate":
                                    management.startJobStatusUpdate();
                                    break;
                            }
                        }
                    }

                    const updateContents: string[] = $form.data("update-contents") != undefined ? $form.data("update-contents").split(",") : null;

                    // Update all contents set on form
                    if (updateContents != null) {
                        for (let i = 0; i < updateContents.length; i++) {
                            management.loadPartialContent(updateContents[i]);
                        }
                    };
                    management.hidePopup();
                    $(".modal").modal("hide");
                } else {
                    // Something went wrong, print error
                    if (response.Message) {
                        $("#error-panel").empty();
                        var $div = $("#error-panel").append("<div/>");
                        $div.append("<p>" + response.Message + "</p>");
                        if (response.ModelState) {
                            var $ul = $("#error-panel").append("<ul/>");
                            for (let j=0;j < response.ModelState.length; j++) {
                                $("#" + response.ModelState[j].Key, $form).closest("tr").addClass("has-error");
                                $ul.append("<li>"+response.ModelState[j].ErrorMessage+"</li>");
                            }
                        }

                    }
                    $("#error-panel").removeClass("hidden");
//                    $(".error").html(response);
                }

            },                    
            error:(xhr, status, error)=> {
                $("#error-panel").text(status + ", " + error);
                $("#error-panel").removeClass("hidden");
                //$(".modal").modal("hide");
                console.log("error");
            }
        });

    }


    // Hide popup
    hidePopup() {
        $(".popup").hide("slow");
    }

    // load partial contents on page or in content (after in hiearchy)
    loadPartialContents(inContent: string = "") {
        let management = this;
        // Load data in partial contents
        $(inContent + " .partial-content").each((i) => {
            var element = $(inContent + " .partial-content").eq(i);
            management.loadPartialContent(element.data("content-id"));
        });
    }

    // Load partial content
    loadPartialContent(contentId: string, callback: (response:any)=>void = null) {
        // Get the content to update
        const $contentToUpdate = $(".partial-content[data-content-id=" + contentId + "]");
        if ($contentToUpdate.length === 0) {
            if (callback != null)
                callback(null);
            return;
        }
        // Get url for content
        const getContentUrl = $contentToUpdate.data("link");

        // Perform ajax request and set the response html to content
        $.ajax({
            url: getContentUrl,
            success(response) {
                //update if changes exists
                if (response !== $contentToUpdate.html()) {
                    $contentToUpdate.html(response);
                    management.updateItemList();
                }
                if (callback)
                    callback(response);
            }
        });
    }

    updateJobStatus() {
        const management = this;
        clearTimeout(management.checkJobStatusTimeout);
        management.startJobStatusUpdatePolls++;
        management.loadPartialContent("currentJobStatus",
            response => {
                //no job is running and we have polled the min number of times
                if (!response && management.startJobStatusUpdatePolls > 10) {
                    management.loadPartialContent("backups");
                    management.loadPartialContent("history");
                } else {
                    //add new timeout
                    let n: any;
                    n = setTimeout(() => { management.updateJobStatus(); }, 1000);
                    management.checkJobStatusTimeout = n;
                }
            });

    }

    // Update status progress bar
    startJobStatusUpdate() {
        const management = this;
        management.startJobStatusUpdatePolls = 0;
        management.updateJobStatus();
    }

    // Show detrails for row
    toggleItemDetails($linkElement: JQuery<Document>) {
        const management = this;
        // Wrapper for details
        let $detailsWrapper = $(".row-details-wrapper");

        // Url for details
        const detailsUrl = $linkElement.attr("href");
        // Hide details wrapper that is already shown and set to not active
        $(".item-details-link").removeClass("active");

        // Set current row to active
        $linkElement.addClass("active");

        var storeName = $linkElement.closest("table").data("item-store");
        Common.saveLocalStorageValue("active"+storeName,
            $linkElement.data("item-id"));

        $.ajax({
            url: detailsUrl,
            success(response) {
                // Set html och row details wrapper
                $detailsWrapper.html(response);

                // clear interval that get backups
                clearInterval(management.checkBackupInterval);

                // Load partial content
                management.loadPartialContent("backups");
                management.loadPartialContent("history");
                management.startJobStatusUpdate();
            }
        });

        return false;
    }
    updateItemList() {
        var storeName = $(".item-table").eq(0).data("item-store");
        if (!storeName)
            return;
        // Get active instance id or set first row to active
        const activeItemId = Common.getLocalStorageValue("active"+storeName);

        let itemId;

        if (activeItemId != null) {
            itemId = Number(activeItemId);
        } else {
            itemId = $(".item-details-link").eq(0).data("item-id");
        }

        // Click on first link at page load
        var $item = $(".item-details-link[data-item-id=" + itemId + "]");
        if (!$item) return;
        if ($item.hasClass("active")) return;
        $(".item-details-link[data-item-id=" + itemId + "]").click();

        management.filterInstances();

    }
}

var management = new Management();